import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#008DDD',
    },
    secondary: {
      main: '#5C00F2',
    },
    error: {
      main: '#FF2B51',
    },
    text: {
      primary: '#000000',
      secondary: '#BFBFBF',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.875rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '2.5rem',
      },
    },
    h2: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    h3: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '2.5rem',
      },
    },
    h4: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    h5: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    h6: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    body1: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 'bolder',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
    caption: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#767676',
      letterSpacing: '0.5px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em',
      },
    },
  },

  overrides: {
    MuiButton: {
      root: {
        padding: '10px 15px 11px',
        fontFamily: 'Inter',
        lineHeight: '18.75px',
        letterSpacing: '1px',
        '&.Mui-disabled': {
          backgroundColor: 'inherit',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&.Mui-disabled': {
          backgroundColor: '#000000',
        },
      },
      containedSecondary: {
        '&.Mui-disabled': {
          backgroundColor: '#ffffff',
        },
      },
      label: {
        textTransform: 'initial',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
      text: {
        textTransform: 'initial',
      },
      outlinedPrimary: {
        '&.Mui-disabled': {
          color: '#000000',
          borderColor: '#000000',
        },
      },
      outlinedSecondary: {
        '&.Mui-disabled': {
          color: '#ffffff',
          borderColor: '#ffffff',
        },
      },
      sizeLarge: {
        textTransform: 'initial',
        padding: '0.5rem 1rem',
        fontWeight: 'bold',
        lineHeight: '2rem',
      },
      sizeSmall: {
        fontSize: '0.875rem',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
        fontWeight: 'bolder',
        padding: '0.25rem 0.5rem',
        fontSize: '0.625rem',
        lineHeight: '0.875rem',
        textTransform: 'uppercase',
      },
      sizeSmall: {
        height: '1.25rem',
        background: '#ffffff',
      },
      labelSmall: {
        paddingLeft: '0',
        paddingRight: '0',
      },
      outlined: {
        borderRadius: '24px',
        padding: '1rem 0.25rem',
        borderColor: '#1E3440',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        fontWeight: 400,
        marginBottom: 0,
      },
      input: {
        padding: '12px 10px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        fontWeight: 400,
        color: '#A9AFB2',
      },

      outlined: {
        transform: 'translate(1rem, 0.875rem) scale(1)',
      },
    },
    MuiInput: {
      multiline: {
        padding: 0,
      },
    },
    MuiOutlinedInput: {
      root: {},
      input: {
        padding: '12px 10px',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
  },
});

export default theme;
