export enum UserActionTypes {
  USER_AUTHENTICATE = 'USER_AUTHENTICATE',
  USER_AUTHENTICATE_DONE = 'USER_AUTHENTICATE_DONE',
  USER_AUTHENTICATE_FAILED = 'USER_AUTHENTICATE_FAILED',
}

export interface UserAuthenticatePayloadTypes {
  payload: {
    username: string;
    password: string;
  };
}
