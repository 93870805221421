import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import { UserAuthenticatePayloadTypes } from './types';
import APILogin from '../../api/login';

export function* userAuthenticateRequest({
  payload,
}: UserAuthenticatePayloadTypes) {
  try {
    const response = yield call(APILogin.requestLoginToken, payload);
    if (response.status === 200) {
      yield put(actions.userAuthenticateDone(response.data.token));
    }
  } catch (error) {
    yield put(actions.userAuthenticateFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default [takeEvery(actions.userAuthenticate, userAuthenticateRequest)];
