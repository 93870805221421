import { IUserState } from '../../types/user/IUser';
import { UserActionTypes } from './types';

const initialUser: IUserState = {
  isLoading: false,
  isError: false,
  userAuthToken: null,
  userDetail: [
    {
      id: 0,
      name: 'Adam',
      email: 'lorem@gmail.com',
    },
  ],
};

const user = (state = initialUser, action: any) => {
  switch (action.type) {
    case UserActionTypes.USER_AUTHENTICATE:
      return { ...state, isLoading: true };
    case UserActionTypes.USER_AUTHENTICATE_DONE:
      return { ...state, isLoading: false, userAuthToken: action.payload.data };
    case UserActionTypes.USER_AUTHENTICATE_FAILED:
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
};

export default user;
