import { Button, Typography } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';
import theme from './themes';

export const GlobalStyles = createGlobalStyle`
  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    font-family: 'Roboto';
  }
  
  *::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
  }
  
  html {
    -ms-overflow-style: none;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: hidden;
    color: #000000;
    scroll-behavior: smooth;
  }
  
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
  }
  
  body,
  html {
    width: 100%;
    height: 100%;
    vertical-align: baseline;
    margin: 0;
    background: #FFFFFF;
    /* remove margin for the main div that Gatsby mounts into */
    >div {
      margin-top: 0;
    }
  }
  
  img {
    width: 100%;
    display: block;
  }
  
  button:focus {
    outline: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const SiteContainer = styled.main`
  flex-grow: 1;
  width: 100%;
`;

export const ContentContainer = styled.div`
  padding: 2.5rem 1.25rem;
  margin: 0 auto;

  ${theme.breakpoints.up('sm')} {
    max-width: 550px;
    padding: 5rem 0;
  }

  ${theme.breakpoints.up('md')} {
    max-width: 940px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 1200px;
  }
`;

export const ResponsiveImage = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`;

export const Text = styled(Typography)`
  font-weight: ${(props) => props.$fontWeight && props.$fontWeight};
  font-size: ${(props) => props.$fontSize && props.$fontSize};
  color: ${(props) => props.$fontColor && props.$fontColor};
  margin-bottom: ${(props) => props.$marginBottom && props.$marginBottom};
  word-break: ${(props) => (props.$wordBreak === true ? 'break-word' : null)};
`;

export const WeightButton = styled(Button)`
  background-color: ${(props) => props.$bgcolor && props.$bgcolor};

  &:hover {
    background-color: ${(props) => props.$bgcolor && props.$bgcolor};
  }

  span {
    font-weight: ${(props) => props.$fontWeight && props.$fontWeight};
    font-size: ${(props) => props.$fontSize && props.$fontSize};
    color: ${(props) => props.$fontColor && props.$fontColor};
  }
`;
