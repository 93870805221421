import { createAction } from '@reduxjs/toolkit';
import { UserActionTypes } from './types';

export const userAuthenticate = createAction(
  UserActionTypes.USER_AUTHENTICATE,
  (username: string, password: string) => ({
    payload: {
      username,
      password,
    },
  })
);

export const userAuthenticateDone = createAction(
  UserActionTypes.USER_AUTHENTICATE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const userAuthenticateFailed = createAction(
  UserActionTypes.USER_AUTHENTICATE_FAILED,
  (error) => {
    console.error('USER_AUTHENTICATE_FAILED', error);
    return { payload: { error } };
  }
);
