import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'localhost:3000',
});

const requestLoginToken = (payload: any) => {
  const { username, password } = payload;
  return axiosInstance
    .post('/api/login/', { username, password })
    .then((response) => response)
    .catch((err) => err);
};

export default { requestLoginToken };
